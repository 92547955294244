// @import '~animate.css/animate.min.css';

// @import "/node_modules/chartist/dist/index.css";

@import "/node_modules/bootstrap/scss/bootstrap.scss";

@import "theme/variables";
@import "style";
@import "theme/responsive";
@import "theme/dashboard_default";

@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "chartist/chartist";

@import "/node_modules/angular-calendar/css/angular-calendar.css";
// @import "/node_modules/flatpickr/dist/flatpickr.css";

@import "todo/todo";
@import "/node_modules/ngx-toastr/toastr.css";
@import "sweetalert/sweetalert2.scss";
@import "~dragula/dist/dragula.css";
@import "sticky/sticky.scss";
@import "~dropzone/dist/min/dropzone.min.css";
// @import "/node_modules/leaflet/dist/leaflet.css";
